@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Barlow", sans-serif;
}

.slide-down {
  animation: slide-down 0.1s linear both;
}

@keyframes slide-down {
  0% {
    visibility: hidden;
    height: 0;
  }
  95% {
    visibility: visible;
    height: 250px;
  }
  /* Set height to 'auto' after animation for spacing showing form-invalid feedback message */
  100% {
    visibility: visible;
    height: auto;
  }
}
